import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Loader } from 'src/components/common/Loader';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { getTransactionData, isPaymentDataValid, reachTextParser } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { setTransactions, setTrasactionNotification } from 'src/store/transactions/actions';
import { RootState } from 'src/types/store-types';
import { configureRedirectWindow } from '../../helpers/transformers';
import GroupedPayments from './components/GroupedPayments';
import GroupedPayment from './components/GroupedPayments/GroupedPayment';
import PaymentInformation from './components/PaymentInformation';
import PaymentNotification from './components/PaymentNotification';
import PaymentTabs from './components/PaymentTabs';
import { Constructor } from './constructor';
import './styles.scss';

type Props = {
  paymentMethodsData: PaymentMethod[] | null;
  groupedPayments: { [key: string]: PaymentMethod[] } | null;
  method: string;
  isLoading: boolean;
  setAdditionalFields: (data: any) => void;
  additionalFields: null;
  paymentFields: Record<string, string>;
  setPaymentFields: (data: any) => void;
  showTransactionResult: boolean;
  setShowTransactionResult: (st: boolean) => void;
};
const PaymentView: FC<Props> = ({
  groupedPayments,
  paymentMethodsData,
  method,
  isLoading,
  additionalFields,
  setAdditionalFields,
  paymentFields,
  setPaymentFields,
  showTransactionResult,
  setShowTransactionResult,
}) => {
  const { locale } = useI18n();
  const { t }: Translation = useTranslation();
  const _setTransactions = useConnectedAction(setTransactions.req);
  const _setTrasactionNotification = useConnectedAction(setTrasactionNotification);

  const { user, wallets } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const { transactionStatus, transactionNotification, transactionLoading } = useSelector(
    (state: RootState) => state.transactions
  );
  const [selectedPaymentID, setSelectedPaymentID] = useState<number>(1);
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod | null>(null);
  const [paymentError, setPaymentError] = useState<{ [keys: string]: string } | null>(null);
  const [redirectWindow] = useState<Window | null>(null);
  const [selectedGroupOfPayment, setSelectedGroupOfPayment] = useState<string>('');

  const handleFieldChange = useCallback(
    (fieldValue: string, fieldName: string): void => {
      setPaymentError(null);

      const addedFields = selectedPayment?.fields[fieldName]?.addedFields;
      const isFieldValueInAddedFields = addedFields && addedFields[fieldValue];
      if (isFieldValueInAddedFields) {
        const _additionalFields = Object.keys(addedFields[fieldValue]).reduce((acc: any, key) => {
          acc[key] = null;
          return acc;
        }, {});

        setAdditionalFields({ [fieldName]: addedFields[fieldValue] });
        setPaymentFields({ ...paymentFields, ..._additionalFields, [fieldName]: fieldValue });
        return;
      }

      const isFieldArrayType = selectedPayment?.fields?.[fieldName]?.type === 'array';
      const isFieldValueNotInAddedFields = addedFields && !Object.keys(addedFields).includes(fieldValue);

      if (isFieldArrayType && isFieldValueNotInAddedFields) {
        setAdditionalFields(null);
        const _fields = { ...selectedPayment?.fields };

        Object.keys(_fields).forEach((key) => (_fields[key] = paymentFields[key]));
        setPaymentFields({ ..._fields, [fieldName]: fieldValue });
        return;
      }

      setPaymentFields((prevState: { hasOwnProperty: (arg0: string) => void }) => {
        const shouldResetAdditionalFields = fieldName !== 'subMethod' && prevState?.hasOwnProperty('subMethod');

        if (shouldResetAdditionalFields) {
          setAdditionalFields(null);
          return { ...prevState, [fieldName]: fieldValue };
        }
        return { ...prevState, ...paymentFields, [fieldName]: fieldValue };
      });
    },
    [selectedPayment, paymentFields, additionalFields]
  );

  const selectPaymentGroup = (group: string): void => {
    setSelectedGroupOfPayment(group);
    setSelectedPaymentID(groupedPayments?.[group][0].id as number);
  };

  const handleSubmit = (): void => {
    if (isPaymentDataValid(selectedPayment, paymentFields, t, wallets, setPaymentError, paymentError)) {
      const transactionData = getTransactionData(selectedPayment, user, method, locale);
      _setTransactions({ ...transactionData, ...paymentFields });
    }
  };

  const closeNotification = (): void => {
    setShowTransactionResult(false);
  };

  const handlePaymentTabClick = (id: number): void => {
    _setTrasactionNotification(null);
    setSelectedPaymentID(id);
    closeNotification();
  };

  const goBackToGroups = (): void => {
    setSelectedGroupOfPayment('');
  };

  useEffect(() => {
    if (!!paymentMethodsData?.length && selectedPayment) {
      setSelectedPayment(
        (paymentMethodsData as PaymentMethod[]).find((el: PaymentMethod) => el.id === selectedPaymentID) ?? null
      );
    }

    setPaymentError(null);
  }, [selectedPaymentID]);

  useEffect(() => {
    if (!!paymentMethodsData?.length) {
      setSelectedPaymentID(paymentMethodsData[0].id);
      setSelectedPayment(
        (paymentMethodsData as PaymentMethod[]).find((el: PaymentMethod) => el.id === paymentMethodsData[0].id) ?? null
      );
    }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (!!groupedPayments && !isMobileOnly) {
      const _defaultGroup = Object.keys(groupedPayments)[0];
      setSelectedGroupOfPayment(_defaultGroup);
      setSelectedPaymentID(groupedPayments[_defaultGroup][0].id);
    }
  }, [groupedPayments]);

  useEffect(() => {
    if (transactionStatus && selectedPayment) {
      configureRedirectWindow(transactionStatus, redirectWindow, selectedPayment);
    }
  }, [transactionStatus]);

  useEffect(() => {
    transactionNotification && setShowTransactionResult(true);
  }, [transactionNotification]);

  const _: any = useMemo(() => Object.values(additionalFields || {}), [additionalFields]);

  if (paymentMethodsData === null) {
    return (
      <div className="no_data_container">
        <Typography variant="h5">{t('payment_no_data')}</Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="loader_container">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <div className="payment_container">
      <div className="payment_content">
        {!!selectedPayment && !!paymentMethodsData?.length && (
          <div className="payment_inner_content">
            {isMobileOnly && selectedGroupOfPayment && groupedPayments && (
              <GroupedPayment
                group={selectedGroupOfPayment}
                groupedPayments={groupedPayments}
                selectedGroupOfPayment={selectedGroupOfPayment}
              />
            )}
            <div className="tabs_section">
              {isMobileOnly && selectedGroupOfPayment && (
                <SvgIcon src={icons.arrowDown} className="back" onClick={goBackToGroups} />
              )}
              <span className="tabs_section__title">{t('choosePaymentMethod')}</span>
            </div>
            <div className="payment">
              {groupedPayments && (
                <div
                  className={cn('payment--grouped-payments', { ['hide']: isMobileOnly && !!selectedGroupOfPayment })}
                >
                  <GroupedPayments
                    groupedPayments={groupedPayments}
                    selectedGroupOfPayment={selectedGroupOfPayment}
                    selectPaymentGroup={selectPaymentGroup}
                  />
                </div>
              )}
              <div className={cn('input_container')}>
                <div
                  className={cn('tabs_section__items', generalConfigs?.paymentTemplate ?? '', {
                    ['grouped']: groupedPayments,
                  })}
                >
                  <PaymentTabs
                    paymentMethodsData={paymentMethodsData}
                    groupedPayments={groupedPayments}
                    selectedPayment={selectedPayment}
                    selectedPaymentID={selectedPaymentID}
                    handlePaymentTabClick={handlePaymentTabClick}
                  />
                </div>
                <div className={cn({ ['grouped_content']: groupedPayments }, 'payment_bottom_content')}>
                  {groupedPayments && (
                    <Typography variant="h6" className={'payment_information_item__name'}>
                      {selectedPayment.name}
                    </Typography>
                  )}
                  <PaymentInformation t={t} selectedPayment={selectedPayment} />
                  {!!selectedPayment?.longDescription?.length && (
                    <div className="payment__pament_rules">{reachTextParser(selectedPayment?.longDescription)} </div>
                  )}
                  <div className={cn('payment_content', { ['grouped']: groupedPayments })}>
                    {selectedPayment?.fields &&
                      Constructor.renderInputs(
                        selectedPayment,
                        handleFieldChange,
                        paymentError,
                        setPaymentError,
                        method
                      )}
                    {!!additionalFields &&
                      Constructor.renderAdditionalInputs(
                        _,
                        handleFieldChange,
                        // bad solution for pixKey method which returns from BE
                        paymentError?.pixType ? { ...paymentError, pixKey: paymentError.pixType } : paymentError,
                        setPaymentError,
                        selectedPayment.id,
                        paymentFields
                      )}
                  </div>
                </div>
                {paymentFields?.pixType === 'mobile' && <p className="phone_example">dddnúmero ex: 11999999999</p>}
                <div className="payment_submit_button">
                  <Button
                    fontWeight="bold"
                    variant="contained"
                    color="primary"
                    className={`success-filled-btn ${transactionLoading ? 'disabled' : ''}`}
                    onClick={handleSubmit}
                    loading={transactionLoading}
                  >
                    {t(paymentMethodsData[0]?.action)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <PaymentNotification
          closeNotification={closeNotification}
          showTransactionResult={showTransactionResult}
          transactionNotification={transactionNotification}
        />
      </div>
    </div>
  );
};
export default PaymentView;
