import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import _styles from './styles.module.scss';

type Props = {
  data: {
    payment: PaymentMethod;
    paymentField: any;
    onchange: (ev: any, field: string) => void;
    error: { [keys: string]: string } | null;
    setError: (error: any) => void;
    forceUpdate: boolean;
    paymentFields?: Record<string, string>;
    isNumeric?: boolean;
  };
};
const Input: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { payment, onchange, paymentField, error, setError, paymentFields, isNumeric } = data;
  const { validation, type } = payment.fields[paymentField];

  const [inpValue, setInpValue] = useState<any>(null);

  const handleInputChange = (ev: any): void => {
    // TO DO make it smarter for all skins with coma and dot
    const value = isNumeric ? ev.target.value.replace(/[^0-9]/g, '') : ev.target.value; // Remove non-numeric characters
    const errors = { ...error };
    delete errors[paymentField];

    setError(errors);
    onchange(value, paymentField);
    setInpValue(value);
  };

  useEffect(() => {
    const errors = { ...error };
    delete errors[paymentField];

    setError(errors);
    setInpValue('');
    onchange('', paymentField);
  }, [payment.methodId, paymentField, payment.action]);

  useEffect(() => {
    paymentFields?.[paymentField] === null && setInpValue('');
  }, [paymentFields]);

  return (
    <div className={_styles.input_container}>
      <label>{t(paymentField)}</label>
      {isNumeric ? (
        <input
          inputMode="numeric"
          type="text"
          name={paymentField}
          value={inpValue}
          onChange={handleInputChange}
          pattern="[0-9]*"
        />
      ) : (
        <input type={type} name={paymentField} value={inpValue} onChange={handleInputChange} />
      )}
      {paymentField === 'amount' && <span className={_styles.payment_currency}>{validation?.range.currency}</span>}
      {error && <ErrorMessage error={error} paymentField={paymentField} />}
    </div>
  );
};
export default Input;
